@import 'src/globalVariables';
.container {
  width: $studioEditControlsColumnWidth;

  .header {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.15px;
    text-align: left;
  }

  .searchBar {
    color: var(--text-primary, #000000);
    background-color: var(--surface-secondary, #f2f4f6);
    border-radius: 8px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 15px;

    input {
      all: unset;
      width: 190px;
    }

    i {
      color: #6f7b8f;
    }
  }

  .pills {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 15px;

    .pillButton {
      background-color: var(--surface-secondary, #f2f4f6);
      padding: 0 7px;
      border-radius: 30px;
      font-size: 10px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.01em;
      text-align: center;
      cursor: pointer;

      &.selected {
        color: var(--interactive-primary, 'unset');
        background-color: var(--surface-tertiary, #ced3dc);
      }
    }
  }

  .tiles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 10px;
    width: 100%;
    max-height: 350px;
    margin-bottom: 20px;
    overflow: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    .tileItem {
      position: relative;
      width: 100%;
      cursor: pointer;

      .tileItemIcon {
        border-radius: 8px;
        width: 100%;
        height: auto;
        aspect-ratio: 1/1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &.selected {
          outline: solid 3px #5e65ff;
          outline-offset: -3px;
        }

        &.selected_ux2 {
          border: solid 3px var(--interactive-primary);
          outline-offset: -3px;
        }
      }

      .tileItemLabel {
        left: 3px;
        bottom: 5px;
        position: absolute;
        font-size: 12px;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: -0.01em;
        text-align: left;
        color: white;
      }
    }
  }

  & .generateButtonWrapper {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 24px;

    .generateButton {
      padding: 15px 45px;
      text-align: center;
      background: linear-gradient(90.2deg, #976aff -0.87%, #5e65ff -0.86%, #5f66ff 23.13%, #5f66ff 23.14%, #976aff 62.79%);
      box-shadow: 0px 2px 4px 0px #00000033;
      border-radius: 30px;
      color: white;
      font-size: 0.8rem;
      white-space: nowrap;

      &:disabled {
        background: var(--disabled-button-color);
        color: #a0aaba;
      }
    }
  }

  & .scrollContainer {
    max-height: 60vh;
    margin-bottom: 20px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: auto;
    overflow-y: auto;
    overflow-x: hidden;
    width: calc(100% + 20px);

    & .scrollInputs {
      width: calc(100% - 20px);
    }
  }
}
