@import 'src/v2/styles/variables/_colors';
@import 'src/v2/styles/variables/_typography';

// ==========================================================================
// Light Mode (Base Theme)
// ==========================================================================

body[data-theme='light'] {
  /********* Other legacy colors ********/
  --generic-transparent: 'transparent';
  --background-color-variant-1: #f9fbfd;
  --background-color-secondary: rgb(217, 222, 228);
  --background-color-variant-2: #ffffff;
  --background-color-variant-3: #e6e9ed;
  --background-color-variant-4: #4305f4;
  --background-color-variant-5: #230091;

  /********* Text - Light Mode **********/
  --text-primary: #{$mirage};
  --text-secondary: #{$gunmetal};
  --text-tertiary: #{$gravel};
  --text-disabled: #{$dove-grey};
  --text-inverse: #{$white};
  --text-unavailable: #{$gunmetal};

  /********* Status - Light Mode **********/
  --status-error: #{cadmium-red};

  /********* Link Colors - Light Mode **********/
  --interactive-primary: #{$electric-indigo};
  --interactive-hover: #{$blue-gem};

  /********* Border Colors - Light Mode **********/
  --border-strong: #{$grey-cloud};
  --border-medium: #{$subtle-shade};
  --border-subtle: #{$desert-storm};

  /********* Background Colors - Light Mode **********/
  --surface-primary: #{$white};
  --surface-secondary: #{$desert-storm};
  --surface-tertiary: #{$fantasy};

  /********* Button - Default - Light Mode **********/
  --button-default-text: #{$white};
  --button-default-background: #{$mirage};
  --button-default-hover-text: #{$white};
  --button-default-hover-background: #{$mirage};
  --button-default-active-text: #{$white};
  --button-default-active-background: #{$black};
  --button-default-unavailable-text: #{$gunmetal};
  --button-default-unavailable-background: #{$grey-cloud};

  /********* Button - Secondary - Light Mode **********/
  --button-secondary-text: #{$mirage};
  --button-secondary-background: transparent;
  --button-secondary-border: #{$mirage};
  --button-secondary-hover-text: #{$mirage};
  --button-secondary-hover-background: #{$fantasy};
  --button-secondary-hover-border: #{$mirage};
  --button-secondary-active-text: #{$mirage};
  --button-secondary-active-background: #{$desert-storm};
  --button-secondary-active-border: #{$mirage};
  --button-secondary-unavailable-text: #{$dove-grey};
  --button-secondary-unavailable-background: transparent;
  --button-secondary-unavailable-border: #{$grey-cloud};

  /********* Button - Tertiary - Light Mode **********/
  --button-tertiary-text: #{$electric-indigo};
  --button-tertiary-background: transparent;
  --button-tertiary-hover-text: #{$blue-gem};
  --button-tertiary-hover-background: #{$fantasy};
  --button-tertiary-active-text: #{$electric-indigo};
  --button-tertiary-active-background: #{$desert-storm};
  --button-tertiary-unavailable-text: #{$dove-grey};
  --button-tertiary-unavailable-background: transparent;

  /********* Slider - Light Mode **********/
  --slider-track-background: #{$electric-indigo};
  --slider-handle-background: #{$electric-indigo};
  --slider-handle-outline: #{$electric-indigo};
  --slider-rail-background: #{$fantasy};

  /********* FONTS RELATED **********/
  // Font Families
  --font-display: #{$font-display};
  --font-text: #{$font-text};
  --font-mono: #{$font-mono};

  // Font Weights
  --weight-regular: #{$weight-regular};
  --weight-bold: #{$weight-bold};

  // Display - Font Size
  --size-display-xxl: #{$size-display-xxl};
  --size-display-xl: #{$size-display-xl};
  --size-display-lg: #{$size-display-lg};
  --size-display-md: #{$size-display-md};
  --size-display-base: #{$size-display-base};
  --size-display-sm: #{$size-display-sm};

  // Display - Line Height
  --line-display-xxl: #{$line-display-xxl};
  --line-display-xl: #{$line-display-xl};
  --line-display-lg: #{$line-display-lg};
  --line-display-md: #{$line-display-md};
  --line-display-base: #{$line-display-base};

  // Text - Font Size
  --size-text-xl: #{$size-text-xl};
  --size-text-lg: #{$size-text-lg};
  --size-text-md: #{$size-text-md};
  --size-text-base: #{$size-text-base};
  --size-text-sm: #{$size-text-sm};
  --size-text-xs: #{$size-text-xs};

  // Text - Line Height
  --line-text-xl: #{$line-text-xl};
  --line-text-lg: #{$line-text-lg};
  --line-text-md: #{$line-text-md};
  --line-text-base: #{$line-text-base};
  --line-text-sm: #{$line-text-sm};

  // Mono - Font Size
  --size-mono-md: #{$size-mono-md};
  --size-mono-base: #{$size-mono-base};
  --size-mono-sm: #{$size-mono-sm};

  // Mono - Line Height
  --line-mono-md: #{$line-mono-md};
  --line-mono-base: #{$line-mono-base};
  --line-mono-sm: #{$line-mono-sm};
}
