.container {
  &:hover {
    & .item {
      border: 1px solid var(--interactive-primary, #4305f4);
      cursor: pointer;
    }

    & .text {
      color: var(--interactive-primary, #4305f4);
    }

    & path {
      fill: var(--interactive-primary, #4305f4);
    }
  }

  .text {
    color: var(--text-primary, #000000);
    cursor: pointer;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--Desktop-spacing-small, 10px);
  width: 50px;
  height: 50px;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  background: var(--surface-tertiary, #f8f9fa);

  & rect {
    fill: var(--surface-tertiary, #f8f9fa);
  }

  & path:not(div[data-id='restyle-icon'], div[data-id='remix-icon'], div[data-id='live-image-icon']) {
    fill: var(--text-primary, #000000);
  }

  & div[data-id='restyle-icon'] path,
  div[data-id='remix-icon'] path,
  div[data-id='live-image-icon'] path {
    fill: none;
    stroke: var(--text-primary, #000000);
    stroke-width: 1px;
  }
}

.text {
  align-self: stretch;
  color: var(--text-primary, #5c687c);
  text-align: center;
  font-family: 'Amazon Ember';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.image {
  object-fit: contain;
}

.active {
  & .item {
    border: 1px solid var(--interactive-primary, #4305f4);
  }

  & .text {
    color: var(--interactive-primary, #4305f4);
  }

  & path {
    fill: var(--interactive-primary, #4305f4);
  }
}
