.referenceImageContainer {
  display: flex;
  flex-direction: column;
  gap: 23px;
  .referenceImagePanel {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .title {
      color: var(--text-primary, #161d26);
      font-feature-settings:
        'liga' off,
        'clig' off;

      /* Desktop/Subhead 2 */
      font-family: 'Amazon Ember';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 20.8px */
      letter-spacing: 0.2px;

      > * {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        letter-spacing: inherit;
      }
    }
  }
  .referenceStrengthPanel {
    max-width: 348px;
    align-items: flex-start;
    align-self: stretch;
    gap: 12px;

    .titles {
      display: flex;
      flex-direction: column;
      gap: 3px;
      .title {
        color: var(--text-primary, #161d26);
        /* Desktop/Subhead 3 */
        font-family: 'Amazon Ember';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 18.2px */
      }
      .subtitle {
        color: var(--text-tertiary, #2d3239);
        font-family: 'Amazon Ember';
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
      }
    }

    & span {
      color: var(--text-primary, 'unset');
    }
  }
}

.referenceImageUploader {
  width: 100%;
}

.referenceImageUploader > div {
  background: transparent;
  width: 100%;

  & :hover:not(svg) {
    background: var(--surface-tertiary, 'unset');
  }
}

.referenceImageThumbnailUploader > div {
  background: transparent;
  & :hover:not(svg) {
    background: var(--surface-tertiary, 'unset');
  }
}

.referenceImageUploader label {
  background: var(--surface-secondary, #f5f3ef);
  border: 1px dashed var(--border-strong, #8a8d8f);
  min-width: 100%;
  height: 75px;
  flex-shrink: 0;

  & > i {
    display: none;
  }

  & > div {
    margin-top: 0;
  }

  .heading {
    display: flex;
    color: var(--text-primary, #454a4f);
    text-align: center;
    gap: 11px;
    font-family: 'Amazon Ember';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.16px;
  }
}

.referenceImageThumbnailUploader label {
  border: 1px dashed var(--Studio-Sytem-Gray-Gray-500, #8a8d8f);
  background: var(--surface-secondary, #f5f3ef);
  max-height: 71px;

  .thumbnailHeading {
    color: var(--text-primary, #454a4f);
    text-align: center;
    font-family: 'Amazon Ember';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.12px;
  }

  & > i {
    display: none;
  }
}

.multiImageGrid {
  display: flex;
  padding: 24px 16px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: var(--text-primary, 'unset');
  }
}
