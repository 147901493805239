// global vars
@import 'src/globalVariables';

#Asset-gallery {
  width: 100%;
  min-width: 180px;

  .skeleton {
    min-height: inherit;
    height: 0;
    flex-grow: 1;
  }

  .navigation {
    position: fixed;
    top: 90%;
    cursor: pointer;
    z-index: 701;

    display: flex;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 40px;
    background: rgba($color: #000000, $alpha: 0.1);

    &.left {
      left: 1%;
      transform: rotate(180deg);
    }

    &.right {
      right: 1%;
    }

    @media (min-width: $mediaBreakPoint-mid) {
      top: 50%;
    }
  }
}
