@import 'src/v2/styles/variables/_colors';
@import 'src/v2/styles/variables/_typography';

// ==========================================================================
// Dark Mode
// ==========================================================================

body[data-theme='dark'] {
  /********* Other legacy colors ********/
  --generic-transparent: 'transparent';
  --background-color-variant-1: #f9fbfd;
  --background-color-secondary: rgb(217, 222, 228);
  --background-color-variant-2: #ffffff;
  --background-color-variant-3: #e6e9ed;
  --background-color-variant-4: #4305f4;
  --background-color-variant-5: #230091;

  // Sets the overall body background color
  background-color: #{$mirage};

  /********* Text - Dark Mode **********/
  --text-primary: #{$white};
  --text-secondary: #{$fantasy};
  --text-tertiary: #{$mercury};
  --text-disabled: #{$grey-cloud};
  --text-error: #{$lavender-rose};
  --text-inverse: #{$mirage};
  --text-unavailable: #{$mirage};

  /********* Status - Dark Mode **********/
  --status-error: #{$lavender-rose};

  /********* Link Colors - Dark Mode **********/
  --interactive-primary: #{$light-purple};
  --interactive-hover: #{$pale-violet};

  /********* Border Colors - Dark Mode **********/
  --border-strong: #{$oslo-grey};
  --border-medium: #{$dove-grey};
  --border-subtle: #{$gunmetal};

  /********* Background Colors - Dark Mode **********/
  --surface-primary: #{$mirage};
  --surface-secondary: #{$gunmetal};
  --surface-tertiary: #{$gravel};

  /********* Button - Default Dark **********/
  --button-default-text: #{$mirage};
  --button-default-background: #{$white};
  --button-default-hover-text: #{$mirage};
  --button-default-hover-background: #{$fantasy};
  --button-default-active-text: #{$mirage};
  --button-default-active-background: #{$fantasy};
  --button-default-unavailable-text: #{$mirage};
  --button-default-unavailable-background: #{$grey-cloud};

  /********* Button - Secondary - Dark Mode **********/
  --button-secondary-text: #{$fantasy};
  --button-secondary-background: transparent;
  --button-secondary-border: #{$fantasy};
  --button-secondary-hover-text: #{$fantasy};
  --button-secondary-hover-background: #{$gravel};
  --button-secondary-hover-border: #{$fantasy};
  --button-secondary-active-text: #{$fantasy};
  --button-secondary-active-background: #{$mirage};
  --button-secondary-active-border: #{$fantasy};
  --button-secondary-unavailable-text: #{$grey-cloud};
  --button-secondary-unavailable-background: transparent;
  --button-secondary-unavailable-border: #{$oslo-grey};

  /********* Button - Tertiary - Dark Mode **********/
  --button-tertiary-text: #{$light-purple};
  --button-tertiary-background: transparent;
  --button-tertiary-hover-text: #{$pale-violet};
  --button-tertiary-hover-background: #{$gravel};
  --button-tertiary-active-text: #{$light-purple};
  --button-tertiary-active-background: #{$mirage};
  --button-tertiary-unavailable-text: #{$grey-cloud};
  --button-tertiary-unavailable-background: transparent;

  /********* Slider - Dark Mode **********/
  --slider-track-background: #{$light-purple};
  --slider-handle-background: #{$light-purple};
  --slider-handle-outline: #{$light-purple};
  --slider-rail-background: #{$gravel};

  /********* FONTS RELATED **********/
  // Font Families
  --font-display: #{$font-display};
  --font-text: #{$font-text};
  --font-mono: #{$font-mono};

  // Font Weights
  --weight-regular: #{$weight-regular};
  --weight-bold: #{$weight-bold};

  // Display - Font Size
  --size-display-xxl: #{$size-display-xxl};
  --size-display-xl: #{$size-display-xl};
  --size-display-lg: #{$size-display-lg};
  --size-display-md: #{$size-display-md};
  --size-display-base: #{$size-display-base};
  --size-display-sm: #{$size-display-sm};

  // Display - Line Height
  --line-display-xxl: #{$line-display-xxl};
  --line-display-xl: #{$line-display-xl};
  --line-display-lg: #{$line-display-lg};
  --line-display-md: #{$line-display-md};
  --line-display-base: #{$line-display-base};

  // Text - Font Size
  --size-text-xl: #{$size-text-xl};
  --size-text-lg: #{$size-text-lg};
  --size-text-md: #{$size-text-md};
  --size-text-base: #{$size-text-base};
  --size-text-sm: #{$size-text-sm};
  --size-text-xs: #{$size-text-xs};

  // Text - Line Height
  --line-text-xl: #{$line-text-xl};
  --line-text-lg: #{$line-text-lg};
  --line-text-md: #{$line-text-md};
  --line-text-base: #{$line-text-base};
  --line-text-sm: #{$line-text-sm};

  // Mono - Font Size
  --size-mono-md: #{$size-mono-md};
  --size-mono-base: #{$size-mono-base};
  --size-mono-sm: #{$size-mono-sm};

  // Mono - Line Height
  --line-mono-md: #{$line-mono-md};
  --line-mono-base: #{$line-mono-base};
  --line-mono-sm: #{$line-mono-sm};
}
