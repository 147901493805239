.thumbnailContainer {
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnailOverlay {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .radioButton {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    position: relative;
    margin: 0;
    padding: 16%;

    &:before {
      content: '';
      position: absolute;
      transform: translate(-50%, -50%);
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2px solid #676b6f;
      box-sizing: border-box;
      background-color: #ffffff;
    }

    &:checked {
      &:before {
        border: 8px solid rgb(22, 29, 38);
      }
    }
  }

  &.center {
    align-items: center;
    justify-content: center;
  }

  &.topLeft {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8%;
  }

  &.topRight {
    align-items: flex-start;
    justify-content: flex-end;
    padding: 8%;
  }

  &.bottomLeft {
    align-items: flex-end;
    justify-content: flex-start;
    padding: 8%;
  }

  &.bottomRight {
    align-items: flex-end;
    justify-content: flex-end;
    padding: 8%;
  }

  &.overlapTopRight {
    justify-content: flex-end;
    align-items: flex-start;
    top: -8px;
    right: -8px;
  }
}

.countBadge {
  background-color: #222831;
  color: #ffffff;
  border-radius: 50%;
  padding: 5px 5px;
  font-size: 12px;
  line-height: 6px;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.12px;
  border: 2px solid #ffffff;
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
