@import 'src/globalVariables';

.container {
  container-name: contentTile;
  container-type: size;

  background-color: var(--surface-tertiary, '#e6e9ed');
  border-radius: 8px;
  aspect-ratio: 1/1;
  display: inline-flex;
  flex-grow: unset;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  max-width: 100%;

  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img.uploadPlaceholderImage {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    backface-visibility: hidden;
    background-color: black;
    border-radius: 14px;
  }

  .uploadBadge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 8px;
    border-radius: 6px;
    background: rgba(22, 29, 38, 0.5);
    color: var(--studio-core-colors-pure-white);

    span {
      font-size: 14px;
      font-weight: 700;
      font-family: 'AmazonEmberMono-Bold';
      text-transform: uppercase;
      color: white;
    }
  }

  .uploadPlaceholderOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    backdrop-filter: blur(10px);
    gap: 12px;
    padding: 6px 20px;
    border-radius: 14px;
    color: white;
  }

  &:has(.displayImageContent),
  &:has(.placeholder),
  &:has(.loading),
  &:has(.uploadPlaceholderImage) {
    border: none;
  }

  .displayImageContent {
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: inherit;
    width: 100%;
    height: 100%;
    background-color: unset;
    border: none;
    transition: 0.5s ease;
  }

  .loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-animation: BackgroundGradient 4s ease infinite;
    animation: BackgroundGradient 4s ease infinite;

    &.lifestyleType {
      background: linear-gradient(45deg, #ba42ce 0%, #255ae1 99.28%);
      background-size: 200% 200%;
    }
    &.productType {
      background: linear-gradient(90deg, #6540ce 0%, #23a8e1 100%);
      background-size: 200% 200%;
    }
  }

  .text {
    display: flex;
    align-items: center;
    font-weight: 100;
    text-align: left;
    color: white;
    font-size: 17px;
    line-height: 24px;
    padding-right: 15px;
    z-index: 1;

    span {
      font-weight: 800;
      padding-right: 4px;
    }

    i {
      color: white;
      font-size: 14px;
      margin-top: 2px;
    }
  }

  .overlayNoHover {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    padding: 12px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    pointer-events: none;
    transition: opacity 0.3s ease;
    visibility: visible;
    opacity: 1;

    .videoIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;

    // pass hover events to the <AssetContentCard />
    pointer-events: none;

    .contentCategory {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      pointer-events: none;

      .contentCategoryTag {
        font-family: 'Amazon Ember Monospace', sans-serif;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 1.5px;
        text-align: center;
        border-radius: 6px;
        padding: 2px 8px;
        color: white;
        background: linear-gradient(90deg, #6236ff 0%, #8631ee 100%);
      }
    }

    .topBar {
      position: absolute;
      top: 10px;
      left: 15px;
      right: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      pointer-events: none;
      gap: 10px;

      .topBarLeft {
        pointer-events: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }

      .thumbs {
        display: flex;

        i {
          cursor: pointer;
        }
      }

      .topBarRight {
        white-space: nowrap;
        pointer-events: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;

        i {
          cursor: pointer;
        }
      }
    }

    .shadow {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background:
        radial-gradient(70.18% 107.02% at 50% 100%, rgba(22, 29, 38, 0.1) 49.78%, rgba(22, 29, 38, 0.6) 93.65%)
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
        radial-gradient(147.27% 100% at 50% 0%, rgba(0, 0, 0, 0) 59.5%, rgba(0, 0, 0, 0.7) 100%);
      cursor: pointer;
    }

    .zoom {
      position: relative;
      width: 15%;
      max-width: 64px;
      aspect-ratio: 1 /1;
      margin-bottom: 25px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAiIGhlaWdodD0iODIiIHZpZXdCb3g9IjAgMCA4MCA4MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxnIG9wYWNpdHk9IjAuNiI+CiAgICAgICAgPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzQyNzFfMjU0OTUpIj4KICAgICAgICAgICAgPHBhdGggZD0iTTM4LjI0MjYgNDIuNzU3MkM0MC41ODU2IDQ1LjEwMDQgNDAuNTg1NiA0OC44OTk2IDM4LjI0MjYgNTEuMjQyOEwyOC40ODUzIDYxSDM0QzM3LjMxMzcgNjEgNDAgNjMuNjg2NCA0MCA2N0M0MCA3MC4zMTM2IDM3LjMxMzcgNzMgMzQgNzNIOFY0N0M4IDQzLjY4NjQgMTAuNjg2MyA0MSAxNCA0MUMxNy4zMTM3IDQxIDIwIDQzLjY4NjQgMjAgNDdWNTIuNTE0OEwyOS43NTc0IDQyLjc1NzJDMzIuMTAwNSA0MC40MTQyIDM1Ljg5OTUgNDAuNDE0MiAzOC4yNDI2IDQyLjc1NzJaTTcyIDlWMzVDNzIgMzguMzEzNyA2OS4zMTM2IDQxIDY2IDQxQzYyLjY4NjQgNDEgNjAgMzguMzEzNyA2MCAzNVYyOS40ODUzTDUwLjI0MjggMzkuMjQyNkM0Ny44OTk2IDQxLjU4NTYgNDQuMTAwNCA0MS41ODU2IDQxLjc1NzIgMzkuMjQyNkMzOS40MTQyIDM2Ljg5OTUgMzkuNDE0MiAzMy4xMDA1IDQxLjc1NzIgMzAuNzU3NEw1MS41MTQ4IDIxSDQ2QzQyLjY4NjQgMjEgNDAgMTguMzEzNyA0MCAxNUM0MCAxMS42ODYzIDQyLjY4NjQgOSA0NiA5SDcyWiIgZmlsbD0id2hpdGUiLz4KICAgICAgICA8L2c+CiAgICA8L2c+CiAgICA8ZGVmcz4KICAgICAgICA8Y2xpcFBhdGggaWQ9ImNsaXAwXzQyNzFfMjU0OTUiPgogICAgICAgICAgICA8cmVjdCB3aWR0aD0iNjQiIGhlaWdodD0iNjQiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4IDkpIi8+CiAgICAgICAgPC9jbGlwUGF0aD4KICAgIDwvZGVmcz4KPC9zdmc+Cg==');
      pointer-events: none;
    }

    .contentMetaContainer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 15px;
      pointer-events: none;

      .contentMetaDescription:first-letter {
        text-transform: uppercase;
      }

      .contentMetaDescription {
        margin-top: 5px;
        font-style: normal;
        letter-spacing: 0.02em;
        text-align: left;
        font-size: 16px;
        line-height: 1.5rem;
        text-shadow: 0 0 20px rgba(0, 0, 0, 1);

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        overflow: hidden;

        @media (min-width: 730px) {
          font-size: 15px;
          line-height: 20px;
        }

        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 18px;
        }

        @media (min-width: 2150px) {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }

  &:hover,
  &.kebabMenuActive,
  &.feedbackPopoverOpen {
    .overlay {
      visibility: visible;
      opacity: 1;
    }
    .overlayNoHover {
      visibility: hidden;
      opacity: 0;
    }
  }
}

.menuWrapper {
  background-color: var(--surface-tertiary, 'unset');
}

@container contentTile (width < 230px) {
  .container .overlay {
    .topBar {
      .topBarLeft .trashCan,
      .topBarRight > *:not(.menu) {
        display: none;
      }
    }
  }
}

@container contentTile (width < 108px) or (height < 80px) {
  .container .overlayNoHover {
    display: none;
  }

  .container .overlay {
    .contentMetaContainer {
      display: none;
    }

    .topBar {
      justify-content: flex-end;

      .topBarLeft,
      .thumbs,
      .topBarRight > *:not(.menu) {
        display: none;
      }
    }
  }
}

@container contentTile (height < 115px) or ((width < 350px) and (height < 255px)) {
  .container .overlay .contentMetaContainer .contentMetaDescription {
    display: none;
  }
}

// hide edit option on mobile for unboxed
@media (max-width: $mediaBreakPoint-sml) {
  .edit {
    display: none;
  }
}

@keyframes BackgroundGradient {
  0% {
    background-position: 10% 0;
  }
  50% {
    background-position: 51% 100%;
  }
  100% {
    background-position: 10% 0;
  }
}

.as_fallback {
  aspect-ratio: 1;
}

.as_1\.91\:1 {
  aspect-ratio: 1.91 / 1;
}
.as_3\:1 {
  aspect-ratio: 3/1;
}
.as_1\:1 {
  aspect-ratio: 1;
}

.as_4\:5 {
  aspect-ratio: 4/ 5;
}
.as_9\:16 {
  aspect-ratio: 9/16;
}
