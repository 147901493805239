.leftPanel {
  display: flex;
  align-self: flex-start;
  justify-content: flex-start;
  flex: 1;
  min-width: 360px;
  width: 360px;
  /* media queries */
  @media (min-width: 980px) {
    width: 360px;
  }
}

.rightPanel {
  background: var(--surface-secondary, #f1f3f5);
  display: flex;
  padding: 20px 44px;
  box-sizing: border-box;
  height: 80vh;
  border-radius: 16px;
  justify-content: flex-start;
  align-items: center;
  flex: 9;
  overflow-y: auto;
}

.flexContainer {
  margin-top: 0px;
  margin-left: 44px;
  margin-right: 44px;
  gap: 26px;
  /* media queries */
  @media (min-width: 980px) {
    /* margin-top: 44px;*/
    margin-left: var(--div-spacing-default);
    margin-right: var(--div-spacing-default);
  }
}
