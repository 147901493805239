.container {
  cursor: auto;
  line-height: 0px;
  display: flex;
  gap: 7.5px;
  width: 60%;
  padding: 10px;
  background: white;
  justify-content: space-between;
  border-radius: 30px;

  .inputFieldStyle {
    all: unset;
    border-radius: 20px;
    background-color: #f5f4f2;
    padding-left: 20px;
    flex: 1;
    font-family: Amazon Ember;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    cursor: text;
    border: 1px solid #00000008;

    & {
      color: #161d26;
    }
  }

  .generateButton {
    all: unset;

    border-radius: 24px;
    padding: 15px 17px;
    display: flex;
    gap: 10px;
    color: white;
    justify-content: center;
    align-items: center;
    position: relative;
    background: linear-gradient(99.75deg, #592fdb 25.6%, #a92dd5 92.12%);
    font-family: Amazon Ember;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    cursor: pointer;

    svg {
      fill: white;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    width: 90%;

    .inputFieldStyle {
      padding: 10px 10px 10px 20px;
      font-family: Amazon Ember;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.01em;
      text-align: left;
    }
  }
}
