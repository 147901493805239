.asset-card {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  height: 100%;

  &.fixed-size {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }

  .assetContainer {
    width: 100%;
    height: 100%;
  }

  &.loaded .assetContainer {
    cursor: pointer;
  }

  .content {
    display: block;
    width: 100%;
    height: 100%;
    max-height: inherit;
    object-fit: cover;
    background-color: transparent;
    transition: transform 0.25s ease;
  }

  .image {
    backface-visibility: hidden;
    opacity: 0;
  }

  .video {
    min-height: inherit;
    margin-bottom: -4px; // TODO: find out why there's a weird gap below for some reason
    opacity: 0;
  }

  &.loaded .image,
  &.loaded .video,
  &.loaded .overlayNoHover {
    transition:
      transform 0.25s ease,
      opacity 0.5s ease;
    opacity: 1;
  }

  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
  }

  .placeholder.loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
  }

  .overlayNoHover {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    display: flex;
    background: rgba(0, 0, 0, 0.1);
    pointer-events: none;

    transition: opacity 0.5s ease;
    visibility: visible;
    opacity: 1;

    .icon {
      position: absolute;
      width: auto;
      height: auto;
      background: transparent;

      &.view {
        width: 70px;
        height: 70px;
        bottom: 20px;
        right: 20px;
      }
      &.video {
        width: 50px;
        height: 50px;
        top: 20px;
        left: 20px;
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    display: flex;
    background: rgba(0, 0, 0, 0.1);
    pointer-events: none;

    transition: opacity 0.5s ease;
    visibility: hidden;
    opacity: 0;

    .clickBox {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.hover:hover .content {
    transform: scale(1.05);
    z-index: 1;
  }

  &.hover:hover .overlay {
    cursor: pointer;
    pointer-events: unset;
    visibility: visible;
    opacity: 1;

    & .info {
      visibility: visible;
      opacity: 1;
    }
  }
}
