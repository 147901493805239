// global vars
@import 'src/globalVariables';

.navigationBar {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  padding: 24px;
  z-index: 3;

  @media (min-width: $mediaBreakPoint-mid) {
    position: sticky;
    justify-content: space-between;
    top: 0;
  }

  .mobileNavOpenIcon {
    display: flex;
    align-items: center;
    cursor: pointer;

    @media (min-width: $mediaBreakPoint-mid) {
      display: none;
    }
  }

  .mobileNavTopContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;

    @media (min-width: $mediaBreakPoint-mid) {
      display: none;
    }

    .darkModeToggleContainer {
      display: flex;
      align-items: center;

      .toggleLabel {
        color: var(--text-primary, #232f3f);
        font-family: 'Amazon Ember';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.52px;
        margin-left: 8px;
      }

      .toggle {
        button {
          background-color: #354152;
        }
      }
    }
  }

  .mobileNavCloseIcon {
    cursor: pointer;
    path {
      fill: var(--text-primary, #232f3f);
    }
  }

  @media (max-width: $mediaBreakPoint-mid) {
    justify-content: space-between;

    .leftContent {
      display: flex;
      justify-content: left;
      flex-direction: row-reverse;
      gap: 14px;
    }

    .navLinks.mobileNavOpen {
      display: flex;
      gap: 12px;
      left: 0;
    }
  }

  .navLinks {
    @media (max-width: $mediaBreakPoint-mid) {
      position: fixed;
      top: 0;
      left: -100%;
      width: 90%;
      max-width: 344px;
      height: 100%;
      flex-direction: column;
      background: var(--background-color-variant-2);
      padding: 24px;
      z-index: 52;
      transition: 0.3s ease-in-out;
    }

    @media (min-width: $mediaBreakPoint-mid) {
      display: flex;
      flex-direction: row;
      gap: 44px;
    }

    .navItem {
      font-family: 'Amazon Ember Monospace', sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 2.5px;
      text-transform: uppercase;
      color: var(--Gray-Gray-700);
      width: fit-content;

      & > div {
        white-space: nowrap;
        cursor: pointer;
        border-bottom: 2px solid transparent;
      }

      &.selected > div,
      & > div:hover {
        color: var(--text-color-variant-1);
      }

      &.selected > div,
      & > div:after {
        border-image: linear-gradient(90deg, #6236ff 0%, #e123c2 55%, #feb424 100%);
        border-image-slice: 1;
        border-bottom: 2px solid;
      }

      &:not(.selected) > div:after {
        display: block;
        content: '';
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
      }

      &:not(.selected) > div:hover:after {
        transform: scaleX(1);
      }
    }

    // hide sandbox link on smaller screen sizes until supported
    .sandbox {
      @media (max-width: $mediaBreakPoint-mid) {
        display: none;
      }
    }
  }

  .pageOverlay {
    position: fixed;
    inset: 0;
    background: linear-gradient(136deg, rgba(98, 54, 255, 0.6) 0.75%, rgba(225, 35, 194, 0.6) 55.34%, rgba(254, 180, 36, 0.6) 100%);
    backdrop-filter: blur(7px);
    z-index: 51;
    transition: 0.3s ease-in-out;
    left: 0;

    &Hidden {
      display: none;
    }
  }

  .rightContent {
    display: flex;
    gap: 24px;

    .darkModeToggleContainer {
      display: none;

      @media (min-width: $mediaBreakPoint-mid) {
        display: flex;
        align-items: center;
      }

      .toggleLabel {
        color: var(--text-primary, #232f3f);
        font-family: 'Amazon Ember';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.52px;
        margin-right: 8px;
      }

      .toggle {
        button {
          background-color: #354152;
        }
      }
    }

    .adsLogo {
      display: none;

      @media (min-width: $mediaBreakPoint-mid) {
        display: block;
        width: 100px;
        height: 25px;
        margin-top: 8px;
      }
    }

    button.menu {
      padding: 5px 0;
    }

    .link {
      align-self: center;
      margin-left: 33px;
      color: white;
      text-align: right;
      /* Removes the underline */
      text-decoration: none !important;
      cursor: pointer;

      &:visited {
        color: white;
        /* Ensures visited links stay the same color */
        text-decoration: none;
        /* Keeps underline off for visited links */
      }

      .linkText {
        color: #4305f4;
        font-size: 16px;
        font-weight: 400;
      }
    }

    .signIn {
      margin-left: 33px;
    }

    @media (max-width: $mediaBreakPoint-sml) {
      .link,
      .signIn {
        display: none;
      }
    }
  }

  .logoContainer {
    display: flex;
    align-items: center;
    margin-right: 5px;

    .logoWrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      font-size: 28px;
      line-height: 32px;
      letter-spacing: 1px;

      .logoTag {
        font-weight: 700;
        color: var(--text-color-variant-7);
        font-size: 11px;
        line-height: 75%;
        letter-spacing: 1px;
        margin-bottom: 20px;
      }
    }
  }

  .userImage {
    border-radius: 50%;
    height: 27px;
    width: 27px;
    object-fit: cover;
  }
}

.navigationBarFullWidth {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  padding: 24px var(--div-spacing-default) 8px;
  z-index: 3;

  @media (min-width: $mediaBreakPoint-mid) {
    position: sticky;
    justify-content: space-between;
    top: 0;
  }

  .mobileNavOpenIcon {
    display: flex;
    align-items: center;
    cursor: pointer;

    & path {
      fill: var(--text-primary, 'unset');
    }

    @media (min-width: $mediaBreakPoint-mid) {
      display: none;
    }
  }

  .mobileNavTopContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;

    @media (min-width: $mediaBreakPoint-mid) {
      display: none;
    }

    .darkModeToggleContainer {
      display: flex;
      align-items: center;

      .toggleLabel {
        color: var(--text-primary, #232f3f);
        font-family: 'Amazon Ember';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.52px;
        margin-left: 8px;
      }

      .toggle {
        button {
          background-color: #354152;
        }
      }
    }
  }

  .mobileNavCloseIcon {
    cursor: pointer;
    path {
      fill: var(--text-primary, #232f3f);
    }
  }

  @media (max-width: $mediaBreakPoint-mid) {
    justify-content: space-between;

    .leftContent {
      display: flex;
      justify-content: left;
      flex-direction: row-reverse;
      gap: 14px;
    }

    .navLinks.mobileNavOpen {
      display: flex;
      gap: 12px;
      left: 0;
    }
  }

  .navLinks {
    @media (max-width: $mediaBreakPoint-mid) {
      position: fixed;
      top: 0;
      left: -100%;
      width: 90%;
      max-width: 344px;
      height: 100%;
      flex-direction: column;
      background: var(--background-color-variant-2);
      padding: 24px;
      z-index: 52;
      transition: 0.3s ease-in-out;
    }

    @media (min-width: $mediaBreakPoint-mid) {
      display: flex;
      flex-direction: row;
      gap: 44px;
    }

    .navItem {
      font-family: 'Amazon Ember Monospace', sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 2.5px;
      text-transform: uppercase;
      color: var(--Gray-Gray-700);
      width: fit-content;

      & > div {
        white-space: nowrap;
        cursor: pointer;
        border-bottom: 2px solid transparent;
      }

      &.selected > div,
      & > div:hover {
        color: var(--text-color-variant-1);
      }

      &.selected > div,
      & > div:after {
        border-image: linear-gradient(90deg, #6236ff 0%, #e123c2 55%, #feb424 100%);
        border-image-slice: 1;
        border-bottom: 2px solid;
      }

      &:not(.selected) > div:after {
        display: block;
        content: '';
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
      }

      &:not(.selected) > div:hover:after {
        transform: scaleX(1);
      }
    }

    // hide sandbox link on smaller screen sizes until supported
    .sandbox {
      @media (max-width: $mediaBreakPoint-mid) {
        display: none;
      }
    }
  }

  .pageOverlay {
    position: fixed;
    inset: 0;
    background: linear-gradient(136deg, rgba(98, 54, 255, 0.6) 0.75%, rgba(225, 35, 194, 0.6) 55.34%, rgba(254, 180, 36, 0.6) 100%);
    backdrop-filter: blur(7px);
    z-index: 51;
    transition: 0.3s ease-in-out;
    left: 0;

    &Hidden {
      display: none;
    }
  }

  .rightContent {
    display: flex;
    gap: 24px;

    .darkModeToggleContainer {
      display: none;

      @media (min-width: $mediaBreakPoint-mid) {
        display: flex;
        align-items: center;
      }

      .toggleLabel {
        color: var(--text-primary, #232f3f);
        font-family: 'Amazon Ember';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.52px;
        margin-right: 8px;
      }

      .toggle {
        button {
          background-color: #354152;
        }
      }
    }

    .adsLogo {
      display: none;

      @media (min-width: $mediaBreakPoint-mid) {
        display: block;
        width: 100px;
        height: 25px;
        margin-top: 8px;
      }
    }

    button.menu {
      padding: 5px 0;
    }

    .link {
      align-self: center;
      margin-left: 33px;
      color: white;
      text-align: right;
      /* Removes the underline */
      text-decoration: none !important;
      cursor: pointer;

      &:visited {
        color: white;
        /* Ensures visited links stay the same color */
        text-decoration: none;
        /* Keeps underline off for visited links */
      }

      .linkText {
        color: #4305f4;
        font-size: 16px;
        font-weight: 400;
      }
    }

    .signIn {
      margin-left: 33px;
    }

    @media (max-width: $mediaBreakPoint-sml) {
      .link,
      .signIn {
        display: none;
      }
    }
  }

  .logoContainer {
    display: flex;
    align-items: center;
    margin-right: 5px;

    .logoWrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      font-size: 28px;
      line-height: 32px;
      letter-spacing: 1px;

      .logoTag {
        font-weight: 700;
        color: var(--text-color-variant-7);
        font-size: 11px;
        line-height: 75%;
        letter-spacing: 1px;
        margin-bottom: 20px;
      }
    }
  }

  .userImage {
    border-radius: 50%;
    height: 27px;
    width: 27px;
    object-fit: cover;
  }
}

.menu {
  ul {
    margin: 0;
  }
}

.menuItemGroup {
  span {
    font-size: 16px !important;
    font-weight: 700 !important;
  }

  ul {
    margin: 0;
  }
}

.accHeading {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #232f3f !important;
}

.accKey {
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 20px;
}

.accValue {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.themeDark {
  @media (max-width: $mediaBreakPoint-mid) {
    background: #161d26 !important;
    color: #ffffff;
    transition: all 0.5s ease;
  }
}

.themeLight {
  @media (max-width: $mediaBreakPoint-mid) {
    background: white !important;
    color: #000000;
    transition: all 0.5s ease;
  }
}
