.header {
  width: fit-content;
}
.wrapper {
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 700px) {
  .wrapper {
    overflow-x: hidden;
  }
}

@media screen and (min-width: 2008px) {
  .wrapper {
    width: 2008px;
    margin-left: auto;
    margin-right: auto;
  }
  .wrapperFullWidth {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
.bottomGutter {
  width: 100%;
  height: 180px;
  position: absolute;
  z-index: -1;
  left: 0;
  margin-bottom: -55px;
}

.themeDark {
  background-color: #161D26;
  color: #ffffff;
  transition: all 0.5s ease;
  transition-property: opacity, transform, background-color;
}

.themeLight {
  background-color: #ffffff;
  color: #000000;
  transition: all 0.5s ease;
  transition-property: opacity, transform, background-color;
}