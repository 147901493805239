@import 'src/globalVariables';

$maxWidthPx: 750px;
$gapPx: 4px;

.imageGrid {
  height: 100%;
  .images {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: $gapPx;
    max-width: $maxWidthPx;
    max-height: 40vh;
    overflow-y: auto !important;

    /* Media Query for Mobile Devices */
    @media (max-width: $mediaBreakPoint-mid) {
      max-height: 70vh;
    }
    img {
      object-fit: cover;
      aspect-ratio: 1/1;
      border-radius: 8px;
      background: transparent;
      color: transparent; // hide with styles but keeps it on element for accessability compliance.
      z-index: 2;
      width: 100%;

      &:hover {
        cursor: pointer;
      }

      @media (max-width: $mediaBreakPoint-sml) {
        width: 100%;
      }
    }

    .feedFetchPlaceholder {
      aspect-ratio: 1;
      min-width: 138px; // sets the width of placeholder items as well so this size was needed atm

      width: calc((100% - (4 * $gapPx)) / 5);

      @media (max-width: $mediaBreakPoint-mid) {
        width: calc((100% - (2 * $gapPx)) / 3);
      }

      @media (max-width: $mediaBreakPoint-sml) {
        width: calc(50% - $gapPx);
      }
    }

    .buttonWrapper {
      position: relative;
      display: flex;
      border-radius: 8px;
      aspect-ratio: 1/1;
      background: none;
      border: none;
      outline: none;
      margin: 0;
      padding: 0;
      box-shadow: none;
      min-width: auto;
      width: calc((100% - (4 * $gapPx)) / 5);

      .checkboxWrapper {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1; /* Ensures the checkbox appears above the image */
      }

      .placeholderWrapper {
        position: absolute;
        display: flex;
        object-fit: cover;
        aspect-ratio: 1/1;
        gap: 4px;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        z-index: 1;

        .placeholder {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        &:hover {
          cursor: pointer;
        }
      }

      @media (max-width: $mediaBreakPoint-mid) {
        width: calc((100% - (2 * $gapPx)) / 3);
      }

      @media (max-width: $mediaBreakPoint-sml) {
        width: calc(50% - $gapPx);
      }
    }
  }
  &.turnoffMaxWidth .images {
    max-width: none;
  }
}
