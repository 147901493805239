.tileWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 10px;
  width: 100%;
  margin-bottom: 24px;

  & .tile {
    color: var(--text-primary, #000000, 'unset');
    background-color: var(--surface-secondary, #f2f4f6);
    display: flex;
    padding-bottom: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 12px;

    & .thumbnail {
      height: 70px;
      width: auto;
      object-fit: contain;
    }

    & .label {
      font-family: Amazon Ember;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
