.flex-container {
  display: flex;
  align-self: baseline;
  flex-direction: column; /* or column */
  align-items: center; /* or flex-start, flex-end, stretch, baseline */
  flex-wrap: wrap;
  width: -webkit-fill-available;
  width: -moz-available;

  /*height: 100vh; /* Adjust as needed */
}

.flex-item {
  margin-top: 0;
  margin-left: 0px;
  margin-bottom: 0px;
  text-align: center;
  flex: 1; /* Adjust as needed */
}

.workflowGuideContainer {
  max-width: 816px;
}

@media (max-width: 1456px) {
  .workflowGuideContainer {
    max-width: 538px;
  }
}

.content {
  align-items: inherit;
  margin-top: 20px;
}
