.dropZone {
  font-family: 'Ember Modern Display Standard';

  h1 {
    display: block;
    width: 100%;
  }

  .modal {
    overflow: hidden;
  }

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      font-weight: bold;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 31.2px */
      letter-spacing: 0.5px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      span {
        color: var(--text-primary, unset);
        font-family: inherit;
      }
    }
  }

  .feedHeader {
    color: var(--text-primary, unset);
    margin: 24px 0;
    font-family: inherit;
  }

  .modalContentDivider {
    border-bottom-color: #edeae3;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .card {
    background: transparent;
    box-shadow: none;
    max-width: 100%;
  }

  .inlineError {
    color: #d82624;
  }

  .previewCard {
    background: var(--surface-secondary, #f1f3f5);
    box-shadow: none;
    width: auto;

    .previewContentWrapper {
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 10px;
      border-radius: 8px;
      color: var(--text-primary, unset);
      background-color: var(--surface-secondary, unset);
    }

    .previewContentImageContainer {
      flex: 1;
    }

    .previewImageCard {
      background: none;
      box-shadow: none;

      .previewImage {
        object-fit: cover;
        width: 150px;
        height: 100px;
        border-radius: 8px;
      }
    }

    .previewTextContent {
      font-size: small;
      text-align: left;
      text-overflow: ellipsis;
      max-width: 160px;
      overflow: hidden;
      flex: 4;
    }

    .previewCloseIcon {
      text-align: right;
      cursor: pointer;
      flex: 2;
    }
  }
}
