.carousel-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  max-width: 1975px;
}

.carousel-container-fullWidth {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  max-width: 100%;
}

.carouselScroll {
  scrollbar-width: none; /* Firefox */
  overflow: -moz-scrollbars-none;
}

.carousel {
  display: flex;
  width: 1380px; /* 1300px item width + 20px left gap + 20px right gap */
}

.carousel-inner {
  display: flex;
  transition: transform 1s ease;
  align-items: flex-start;
}

.carousel-item {
  width: 1300px;
  border-radius: 16px;
  flex-shrink: 0;
  /*background-color: #6f7b8f;*/
  text-align: center;
  line-height: 300px; /* Adjust this based on your content */
  font-size: 24px;

  &:not(:first-child) {
    margin: 0 10px;
  }

  @media (min-width: 2004px) {
    margin: 0 10px !important;
  }
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
}

.carousel-nav {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 5px;
  position: absolute;
  bottom: 0;
}

.carousel-nav-button {
  cursor: pointer;
}

.carousel-nav-button.active {
  circle {
    fill: #4305f4;
  }
}
