.inputFieldStyle {
  div {
    border: none;
    background-color: var(--surface-tertiary, #f1f3f5);
    box-shadow: none;

    input {
      min-height: 30px;
    }

    & textarea::placeholder {
      color: var(--text-disabled, #000000) !important;
    }

    & textarea {
      color: var(--text-primary, #000000) !important;
    }
  }
}
