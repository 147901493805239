// global vars
@import 'src/globalVariables';

.imagePreviewTab {
  display: flex;
  gap: 20px;
  width: 100%;
  position: relative;

  // use these 3 lines for horizontal layout modal preview
  flex-direction: row;
  align-items: flex-start;
  height: 100%;

  &.mediaOnly {
    justify-content: center;
  }

  // use these 3 lines for vertical layout modal preview
  // height: 80vh;
  // flex-direction: column;
  // align-items: center;

  @media (max-width: $mediaBreakPoint-lrg) {
    flex-direction: column;
    align-items: center;
  }

  & .leftPanel {
    border-radius: 20px;
    max-width: 75%;

    @media (max-width: $mediaBreakPoint-lrg) {
      max-width: 100%;
    }

    & img,
    & video {
      max-width: max-content;
      max-height: 60vh;
      border-radius: 10px;

      @media (max-width: $mediaBreakPoint-lrg) {
        max-height: 50vh;
      }

      @media (max-width: $mediaBreakPoint-sml) {
        max-height: 30vh;
      }
    }
  }

  & .rightPanel {
    width: 100%;
    max-width: unset;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    & .rightPanelContent {
      display: flex;
      flex-direction: column;
      gap: 22px;
      width: 100%;
    }

    & .section {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      & .sectionHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: var(--text-primary, #000000);
        }
      }

      & .subSection {
        display: flex;
        gap: 12px;
        flex-direction: column;

        & p {
          color: var(--text-primary, #000000);
        }
      }

      & .theme {
        display: flex;
        align-items: center;
        gap: 8px;

        & img {
          width: 40px;
          height: 40px;
          cursor: pointer;
          border-radius: 10px;
        }
      }

      & .originalImage {
        border-radius: 10px;
        max-width: max-content;
        max-height: 50vh;

        @media (max-width: $mediaBreakPoint-lrg) {
          max-height: 40vh;
        }

        @media (max-width: $mediaBreakPoint-sml) {
          max-height: 20vh;
        }
      }
    }

    & .box {
      background: var(--surface-secondary, #f8f9fa);
      border-color: var(--Gray-Gray-25, #f8f9fa);
      padding: 12px 10px;

      & .headlineText {
        font-style: italic;
      }
    }
  }
}
