.feedbackCommentModal {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: var(--text-primary, 'unset');
  }

  > div:first-child {
    border-radius: 12px;
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    width: 480px;
    gap: 18px;

    .topSection {
      display: flex;
      gap: 12px;

      img.imageBox,
      video.imageBox {
        width: 100px;
        height: 100px;
        object-fit: cover;
        flex-grow: 0;
        flex-shrink: 0;
        font-size: 12px;
        border-radius: 8px;
      }

      .instructions {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .instructionsHeading {
        font-size: 16px;
        font-weight: 700;
      }

      .instructionsText {
        font-size: 12px;
      }
    }

    .commentTextArea {
      & textarea:focus-visible,
      textarea:focus-within {
        outline: none;
        border: none;
      }

      div {
        border: none;
        background: none;

        textarea {
          min-height: 214px;
          background-color: var(--surface-secondary, #f8f9fa);
          color: var(--text-primary, '#232f3f');
          border-radius: 12px;
          padding: 10px;
        }
      }
    }
  }
}
