.placeholder {
  --sparkle-gradient: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3ClinearGradient id='customGradient' gradientTransform='scale(1.2)'%3E%3Cstop offset='11.04%25' stop-color='%236236FF'/%3E%3Cstop offset='73.41%25' stop-color='%23E123C2'/%3E%3Cstop offset='100%25' stop-color='%23FFEF42'/%3E%3C/linearGradient%3E%3C/svg%3E#customGradient");
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;

  border: none;
  border-radius: 4px;
  box-sizing: border-box;

  background-color: rgba(0, 0, 0, 0.11);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  &.colorMode-cool {
    background-color: rgba(0, 0, 0, 0.11);
  }
  &.colorMode-warm {
    background-color: var(--Background-Level-2, #f5f3ef);
  }
  &.colorMode-darkMode {
    background-color: var(--Background-Level-2, #454a4f);
  }

  & svg > path {
    fill: var(--sparkle-gradient);
  }

  .progress {
    max-width: 190px;
    & > div > div {
      background: linear-gradient(90deg, #6236ff 0%, #8631ee 100%);
    }
  }

  &.variant-wide {
    align-items: flex-start;

    .progress {
      max-width: 100%;
    }
  }
}
