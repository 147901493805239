.placeholder {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.11);

  &.colorMode-cool {
    background-color: rgba(0, 0, 0, 0.11);
  }
  &.colorMode-warm {
    background-color: var(--surface-tertiary);
  }
  &.colorMode-darkMode {
    background-color: var(--surface-tertiary);
  }

  &.variant-rounded {
    border-radius: 8px;
  }

  &.variant-dotted {
    border: 1px dashed var(--outline-boarder-primary, #aaabac);
    border-radius: 8px;
  }

  &.animation-pulsate {
    animation: animation-pulsate 2s ease-in-out 0.5s infinite;
  }

  &.animation-wave {
    mask-image: radial-gradient(center, white, black);
    -webkit-mask-image: -webkit-radial-gradient(center, white, black);
    overflow: hidden;
  }

  &.animation-wave::after {
    animation: animation-wave 2s linear infinite;
    background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);
    content: '';
    position: absolute;
    transform: translateX(-100%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@keyframes animation-pulsate {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-wave {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}
