.container {
  display: flex;
  flex-direction: column;
  gap: 14px;

  & .canvasContainer {
    min-width: 300px;
    width: fit-content;
  }

  & .controlSection {
    display: flex;
    justify-content: flex-end;
  }

  & .resetButton {
    margin-top: -14px;
    padding: 8px;
    border: 1px solid rgb(251, 251, 252);
    border-radius: 8px !important;
    color: rgb(160, 170, 186) !important;
    box-shadow: none !important;
    background-color: rgb(251, 251, 252) !important;

    &:hover {
      color: rgb(67, 5, 244) !important;
    }
  }
}
