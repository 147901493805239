$mediaBreakPoint-lrg: 1320px;
$mediaBreakPoint-mid: 1100px;
$mediaBreakPoint-sml: 700px;

// studio edit modal
$studioEditControlsColumnWidth: 276px;

// animation
$inputBoxAnimationDuration: 500ms;
$inputBoxAnimationDelay: 417ms;

// UX 2.0 Breakpoints
// width >= <breakpoint value>
$breakpointXL: 2008px;
$breakpointLG: 1388px;
$breakpointMD: 988px;
$breakpointSM: 0px;

// Shared Animations
@keyframes animate-shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes animate-up-down {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(-10%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes animate-down-up {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(10%);
  }

  100% {
    transform: translateY(0%);
  }
}
