img.uploadPlaceholderImage {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  backface-visibility: hidden;
  background-color: black;
  border-radius: 14px;
}

.uploadBadge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 6px;
  background: rgba(22, 29, 38, 0.5);
  color: var(--studio-core-colors-pure-white);

  span {
    font-size: 14px;
    font-weight: 700;
    font-family: 'AmazonEmberMono-Bold';
    text-transform: uppercase;
    color: white;
  }
}

.uploadPlaceholderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  backdrop-filter: blur(10px);
  gap: 12px;
  padding: 6px 20px;
  border-radius: 14px;
  color: white;
}
