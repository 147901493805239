@import 'src/globalVariables';
@import 'src/v2/styles/variables/_colors';

$feedGap: 8px;

.notFetchingBottom {
  display: flex;
  width: 100%;
  height: 1px;
  visibility: hidden;
}

/* aka container in ContentTile.module.scss */
.feedItemContainer {
  position: relative;

  display: inline-flex;
  flex-grow: unset;
  align-items: center;
  justify-content: center;

  container-name: feedItemContainer;
  container-type: size;

  aspect-ratio: 1 / 1;
  overflow: hidden;
  max-width: 100%;
  border-radius: 8px;

  background-color: var(--surface-secondary);
}

.feedItemContainer.feedAssetContainer {
  max-height: 100%;

  .feedAsset {
    transition: transform 0.3s ease;
  }

  .feedAssetOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    color: white;
    visibility: hidden;
    opacity: 0;

    transition: 0.3s;
    transition-property: opacity;

    // pass hover events to the <AssetContentCard />
    pointer-events: none;

    .shadow {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      background:
        radial-gradient(70.18% 107.02% at 50% 100%, rgba(22, 29, 38, 0.1) 49.78%, rgba(22, 29, 38, 0.6) 93.65%)
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
        radial-gradient(147.27% 100% at 50% 0%, rgba(0, 0, 0, 0) 59.5%, rgba(0, 0, 0, 0.7) 100%);

      cursor: pointer;
      pointer-events: auto;
    }

    .controls {
      position: absolute;
      top: 10px;
      left: 15px;
      right: 15px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      pointer-events: none;

      .leftContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;

        pointer-events: auto;

        button {
          display: flex;
        }

        .deleteButton {
          &:hover {
            animation: animate-shake 0.25s linear;
          }
        }
      }

      .rightContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;

        white-space: nowrap;

        pointer-events: auto;

        .editButton {
          &:hover {
            background-color: #{$gravel};
          }
        }

        .menuToggleButton {
          &:hover {
            animation: animate-shake 0.4s linear;
          }
        }
      }
    }

    .metadataContainer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      padding: 15px;

      pointer-events: none;

      .contentTypeContainer {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        pointer-events: none;

        .contentTypeBadge {
          font-family: 'Amazon Ember Monospace', sans-serif;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 700;
          line-height: 18px;
          letter-spacing: 1.5px;
          text-align: center;
          border-radius: 6px;
          padding: 2px 8px;
          color: white;
          background: linear-gradient(90deg, #6236ff 0%, #8631ee 100%);
        }
      }

      .headlineContainer:first-letter {
        text-transform: uppercase;
      }

      .headlineContainer {
        margin-top: 5px;
        font-style: normal;
        letter-spacing: 0.02em;
        text-align: left;
        font-size: 16px;
        line-height: 1.5rem;
        text-shadow: 0 0 20px rgba(0, 0, 0, 1);

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        overflow: hidden;

        @media (min-width: 730px) {
          font-size: 15px;
          line-height: 20px;
        }

        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 18px;
        }

        @media (min-width: 2150px) {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }

  .feedAssetNoHoverOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;
    padding: 12px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    opacity: 1;

    transition: opacity 0.3s ease;
    pointer-events: none;

    .uploadedBadge {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 2px 8px;
      border-radius: 6px;
      background: rgba(22, 29, 38, 0.5);
      color: #fff;

      span {
        font-size: 14px;
        font-weight: 700;
        font-family: 'AmazonEmberMono-Bold';
        text-transform: uppercase;
        color: inherit;
      }
    }

    .videoPlayIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      opacity: 0.95;
    }
  }

  &:hover .feedAssetOverlay,
  .feedAssetOverlay.feedbackPopoverOpen,
  .feedAssetOverlay.menuOpen {
    visibility: visible;
    opacity: 1;

    ~ .feedAsset {
      transform: scale(1.05);
    }

    ~ .feedAssetNoHoverOverlay {
      opacity: 0;
    }
  }

  &.assetLoading {
    .feedAssetOverlay,
    .feedAssetNoHoverOverlay {
      display: none;
    }
  }
}

.feedItemContainer.feedEmptyPlaceholder,
.feedItemContainer.feedFetchPlaceholder {
  aspect-ratio: 1.91 / 1;

  // 1 feed item per row
  flex-basis: 100%;

  // 2 feed items per row
  @media (width >= $breakpointMD) {
    flex-basis: calc((100% - $feedGap) / 2);
  }

  // 3 feed items per row
  @media (width >= $breakpointLG) {
    flex-basis: calc((100% - 2 * $feedGap) / 3);
  }
}

.feedItemContainer.feedEmptyPlaceholder,
.feedItemContainer.feedAssetGenerationPlaceholder,
.feedItemContainer.feedFetchPlaceholder {
  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@container feedItemContainer (width < 230px) {
  .feedItemContainer.feedAssetContainer {
    .feedAssetOverlay {
      .controls {
        .leftContainer .deleteButton,
        .rightContainer > *:not(.menu) {
          display: none;
        }
      }
    }
  }
}

@container feedItemContainer (width < 108px) or (height < 80px) {
  .feedItemContainer.feedAssetContainer {
    .feedAssetNoHoverOverlay {
      display: none;
    }

    .feedAssetOverlay {
      .metadataContainer {
        display: none;
      }

      .controls {
        justify-content: flex-end;

        .leftContainer,
        .rightContainer > *:not(.menu) {
          display: none;
        }
      }
    }
  }
}

@container feedItemContainer (height < 115px) or ((width < 350px) and (height < 255px)) {
  .feedItemContainer.feedAssetContainer {
    .feedAssetOverlay {
      .metadataContainer {
        .headlineContainer {
          display: none;
        }
      }
    }
  }
}
