@import 'src/globalVariables';

.tabbedNavigation {
  & li[role='presentation']::after {
    background-color: var(--interactive-primary, #4305f4);
  }

  & li,
  button {
    & textarea:focus-visible,
    textarea:focus-within {
      outline: none;
      border: none;
    }
  }

  @media (max-width: $mediaBreakPoint-sml) {
    display: none;
  }
}
