.assetImage,
.assetVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;

  &:not(.assetLoaded) {
    visibility: hidden;
  }
}

.assetLoadingPlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.assetLoaded {
    display: none;
  }
}
