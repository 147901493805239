// global vars
@import 'src/globalVariables';

body {
  margin: 0;
  font-family: var(--default-font-family), serif;
}

.pageContainer {
  height: 90vh;
  max-width: 100vw;
  margin: 0 auto;
  padding: 0;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'content sidebar';
  grid-template-rows: auto;
  gap: 0px;

  @media (min-width: $mediaBreakPoint-sml) {
    max-width: 1920px;
  }

  @media (min-width: $mediaBreakPoint-mid) {
    /* gap: 20px; */
    margin: 0 44px;
  }

  &.bringYourOwnImage {
    grid-template-areas:
      'toolbar toolbar'
      'content sidebar';
    grid-template-rows: 58px auto;

    .toolbar {
      grid-area: toolbar;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 23px;
      padding-right: 20px;
      max-width: 100vw;

      @media (min-width: $mediaBreakPoint-mid) {
        padding-right: 0px;
      }
    }
  }

  .panel {
    position: fixed;
    left: 100vw;
    top: 0;
    height: 100vh;
    z-index: 51;
    background-color: white;
    transition: left 500ms;
    padding: 10px 20px;

    &.show {
      left: calc(100vw - 280px);
    }

    @media (min-width: $mediaBreakPoint-mid) {
      z-index: 5;
      padding: 0;
      position: unset;
      grid-area: sidebar;
      width: 0px;
      height: auto;
      overflow: scroll;
      margin-bottom: 50px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      width: 240px;
      margin-left: 20px;
      margin-bottom: 10px;
      min-height: fit-content;
    }

    &::-webkit-scrollbar {
      display: none;
    }
    .panelToggle {
      height: 0;
      z-index: 10;
      @media screen and (min-width: $mediaBreakPoint-mid) {
        display: none;
      }
      .panelToggleButton {
        cursor: pointer;
        background-color: white;
        position: relative;
        left: -40px;
        margin-left: -30px;
        top: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 60px;
        border-radius: 10px;
      }
    }
  }

  .canvas {
    grid-area: content;
    width: 100vw;
    min-width: 440px;
    max-width: 1660px;
    overflow: hidden;
    position: relative;
    margin-bottom: 5px;

    @media (min-width: $mediaBreakPoint-mid) {
      width: calc(100vw - 360px);
      overflow: hidden;
    }

    .userInputs {
      position: absolute;
      pointer-events: none;
      z-index: 3;
      padding: 1px;
      bottom: 8px;
      left: 0;

      @media (max-width: $mediaBreakPoint-sml) {
        margin: 0;
      }

      @media (max-width: $mediaBreakPoint-mid) {
        position: fixed;
        width: 100%;
        left: auto;
        right: auto;
        bottom: 0;
      }

      @media (min-width: $mediaBreakPoint-mid) {
        left: 5%;
        right: 5%;
      }

      @media (min-width: $mediaBreakPoint-lrg) {
        bottom: 10px;
        left: 10%;
        right: 10%;
      }
    }
  }
}

.pageContainerFullWidth {
  height: 95vh;
  max-width: 100vw;
  margin: 0 auto;
  padding: 0;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'content sidebar';
  grid-template-rows: auto;
  gap: 0px;

  &.bringYourOwnImage {
    grid-template-areas:
      'toolbar toolbar'
      'content sidebar';
    grid-template-rows: 58px auto;

    .toolbar {
      grid-area: toolbar;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 23px;
      padding-right: 20px;
      max-width: 100vw;

      @media (min-width: $mediaBreakPoint-mid) {
        padding-right: 30px;
      }
    }
  }

  .panel {
    position: fixed;
    left: 100vw;
    top: 0;
    height: 100vh;
    z-index: 51;
    background-color: white;
    transition: left 500ms;
    padding: 10px 20px;

    &.show {
      left: calc(100vw - 280px);
    }

    @media (min-width: $mediaBreakPoint-mid) {
      z-index: 5;
      padding: 0;
      position: unset;
      grid-area: sidebar;
      width: 0px;
      height: auto;
      overflow: scroll;
      margin-bottom: 50px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      width: 240px;
      margin-left: 20px;
      margin-bottom: 10px;
      min-height: fit-content;
    }

    &::-webkit-scrollbar {
      display: none;
    }
    .panelToggle {
      height: 0;
      z-index: 10;
      @media screen and (min-width: $mediaBreakPoint-mid) {
        display: none;
      }
      .panelToggleButton {
        cursor: pointer;
        background-color: white;
        position: relative;
        left: -40px;
        margin-left: -30px;
        top: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 60px;
        border-radius: 10px;
      }
    }
  }

  .canvas {
    grid-area: content;
    width: 100vw;
    min-width: 440px;
    overflow: hidden;
    position: relative;
    margin-bottom: 5px;

    @media (min-width: $mediaBreakPoint-mid) {
      width: 100vw;
      overflow: hidden;
    }

    .userInputs {
      position: absolute;
      pointer-events: none;
      z-index: 3;
      padding: 1px;
      bottom: 8px;
      left: 0;

      @media (max-width: $mediaBreakPoint-sml) {
        margin: 0;
      }

      @media (max-width: $mediaBreakPoint-mid) {
        position: fixed;
        width: 100%;
        left: auto;
        right: auto;
        bottom: 0;
      }

      @media (min-width: $mediaBreakPoint-mid) {
        left: 5%;
        right: 5%;
      }

      @media (min-width: $mediaBreakPoint-lrg) {
        bottom: 10px;
        left: 10%;
        right: 10%;
      }
    }
  }
}
